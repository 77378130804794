import { Box, Typography, type SxProps } from '@mui/material';
import { flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import TimeAgo from 'timeago-react';
import { formatEpochSeconds } from '../util/misc-utils';

interface Props {
  className?: string;
  sx?: SxProps;

  /**
   * 시간
   */
  datetime: Date | number;

  /**
   * 텍스트 정렬 방식
   */
  alignment?: 'left' | 'center' | 'right';

  /**
   * 배치 방향 : 가로 또는 세로
   */
  vertical?: boolean;

  /**
   * 상대시간을 뒤에 표시할 지 여부
   */
  relativeLast?: boolean;

  /**
   * 상대시간 감추기 여부
   */
  hideRelative?: boolean;

  /**
   * 포맷시간 감추기 여부
   */
  hideFormatted?: boolean;

  /**
   * dayjs 날짜 포맷
   */
  dateFormat?: string;

  /**
   * fullDateFormat=true인 경우 'YYYY.MM.DD HH:mm:ss' 포맷을 사용
   * 로그 표시할 때 자주 사용됨
   */
  fullDateFormat?: boolean;
}

/**
 * 게시물에서 사용하는 Datetime
 * 상대 시간을 표시한다
 */
export function BbsDatetimeBox(props: Props) {
  const {
    sx,
    className,
    datetime,
    hideRelative = false,
    hideFormatted = false,
    vertical = false,
    alignment = 'left',
    relativeLast = false,
    fullDateFormat = false,
    dateFormat,
  } = props;

  const dateSeconds = typeof datetime === 'number' ? datetime : datetime.getTime() / 1000;
  const [dateStr, date] = formatEpochSeconds(
    dateSeconds,
    fullDateFormat ? 'YYYY.MM.DD HH:mm:ss' : dateFormat,
  );

  let relativeColor = '#444';
  let formattedColor = '#666';

  // 만약 둘중 한개만 표시해야 하면 글자색은 진하게
  if (hideRelative || hideFormatted) {
    relativeColor = '#444';
    formattedColor = '#444';
  }

  return (
    <Box
      className={clsx('BbsDatetimeBox-root', className, {
        x_vertical: vertical,
        x_horizontal: !vertical,
      })}
      sx={flatSx(
        {
          '&.x_vertical': {
            display: 'flex',
            flexDirection: relativeLast ? 'column-reverse' : 'column',
            alignItems:
              alignment === 'left' ? 'flex-start' : alignment === 'center' ? 'center' : 'flex-end',
          },
          '&.x_horizontal': {
            display: 'flex',
            flexDirection: relativeLast ? 'row-reverse' : 'row',
            justifyContent:
              alignment === 'left' ? 'flex-start' : alignment === 'center' ? 'center' : 'flex-end',
            '& > .MuiTypography-root + .MuiTypography-root': {
              ml: 1,
            },
          },

          '& .BbsDatetimeBox-relative': {
            whiteSpace: 'nowrap',
            fontWeight: 500,
            textAlign: 'inherit',
            color: relativeColor,
            lineHeight: 1.5,
          },

          '& .BbsDatetimeBox-formatted': {
            whiteSpace: 'nowrap',
            fontWeight: 400,
            textAlign: 'inherit',
            color: formattedColor,
            lineHeight: 1.7,
          },
        },
        sx,
      )}
    >
      {!hideRelative && (
        <Typography variant="body2" className="BbsDatetimeBox-relative">
          <TimeAgo datetime={date} live={false} locale="ko" />
        </Typography>
      )}

      {!hideFormatted && (
        <Typography variant="body2" className="BbsDatetimeBox-formatted">
          {dateStr}
        </Typography>
      )}
    </Box>
  );
}
