'use client';

import Box, { type BoxProps } from '@mui/material/Box';
import { Center, flatSx } from '@ocodelib/mui';
import clsx from 'clsx';
import NextImage from 'next/image';
import { type ReactNode } from 'react';
import { DelayMount } from './DelayMount';

interface Props {
  sx?: BoxProps['sx'];
  className?: string;
  title?: ReactNode;
  visible?: boolean;
  delay?: number;
}

export function NoDataView(props: Props) {
  const { sx, className, title = '데이터가 없습니다.', visible = false, delay = 300 } = props;

  if (!visible) {
    return null;
  }

  return (
    <DelayMount delayMs={delay}>
      <Center
        vertical
        className={clsx('NoDataView-root', className)}
        sx={flatSx(
          {
            position: 'relative',
            width: '100%',
            pb: 4,
          },
          sx,
        )}
      >
        <Center
          sx={{
            width: 200,
            mt: 10,
            mb: { xs: 2, sm: 3 },
            '& img': {
              objectFit: 'contain',
              width: '100%',
              height: 'auto',
            },
          }}
        >
          <NextImage
            src="/_static/images/no-data/img_no_data1.png"
            alt=""
            width={200}
            height={150}
          />
        </Center>
        <Box sx={{ fontSize: '1.1rem', lineHeight: 1.5, fontWeight: 400, color: '#4F566F' }}>
          {title}
        </Box>
      </Center>
    </DelayMount>
  );
}
